import post1 from '../images/post1.png'
import post2 from '../images/post2.png'
import post3 from '../images/post3.png'
import post4 from '../images/post4.png'

export const posts = [
    {id:0, img:post1, link:'https://www.instagram.com/p/Cgj_OnNKyB3/?igshid=YmMyMTA2M2Y='},
    {id:1, img:post2, link:'https://www.instagram.com/p/CWNeOROLyzP/?igshid=YmMyMTA2M2Y='},
    {id:2, img:post3, link:'https://www.instagram.com/p/CSpWhfGLXRd/?igshid=YmMyMTA2M2Y='},
    {id:3, img:post4, link:'https://www.instagram.com/p/Ce9C8T1K0GC/?igshid=YmMyMTA2M2Y='}
]