import Whimsicallogo from '../images/Whimsicallogo.png'
import Mazelogo from '../images/Mazelogo.png'
import Figjamlogo from '../images/Figjamlogo.png'
import Splinelogo from '../images/Splinelogo.png'
import HTMLlogo from '../images/HTMLlogo.png'
import Notionlogo from '../images/Notionlogo.png'
import Sketchlogo from '../images/Sketchlogo.png'
import XDlogo from '../images/XDlogo.png'
import Zeplinelogo from '../images/Zeplinelogo.png'
import figmalogo from '../images/figmalogo.png'

export const carouselItems = [
    {id:0, img:Whimsicallogo},
    {id:1, img:Mazelogo},
    {id:2, img:Figjamlogo},
    {id:3, img:Splinelogo},
    {id:4, img:HTMLlogo},
    {id:5, img:Notionlogo},
    {id:6, img:Sketchlogo},
    {id:7, img:XDlogo},
    {id:8, img:Zeplinelogo},
    {id:9, img:figmalogo}
]