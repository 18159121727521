export const navlinks = [
    {
        id: 1,
        name:'Home',
        url:"/"
    },
    {
        id: 2,
        name: "About",
        url:"/about"
    },
    {
        id: 3,
        name: "My Work",
        url:"/my_work"

    },
    {
        id: 4,
        name: "Posts",
        url:"/posts"
    }
    ]